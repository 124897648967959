import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { commonCn } from '@utils/cn';
import { WithPopupContextProps, withPopupContext } from '@utils/popup-context';
import { prepareHtmlString } from '@utils/prepare-html-string';

/**
 * A customized tooltip content component.
 */
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & WithPopupContextProps
>(({ className, onPointerDownOutside, sideOffset = 4, children, zIndex, ...props }, ref) => {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        ref={ref}
        onPointerDownOutside={
          onPointerDownOutside ??
          ((e) => {
            e.preventDefault();
          })
        }
        sideOffset={sideOffset}
        className={commonCn(
          'ps-max-w-[200px] ps-overflow-hidden ps-rounded ps-bg-gray-800 ps-p-1 ps-text-sm ps-text-white ps-shadow-md ps-animate-in ps-fade-in-0 ps-zoom-in-95 data-[state=closed]:ps-animate-out data-[state=closed]:ps-fade-out-0 data-[state=closed]:ps-zoom-out-95 data-[side=bottom]:ps-slide-in-from-top-2 data-[side=left]:ps-slide-in-from-right-2 data-[side=right]:ps-slide-in-from-left-2 data-[side=top]:ps-slide-in-from-bottom-2 sm:ps-max-w-[350px]',
          className
        )}
        style={{ zIndex, ...props.style }}
        {...props}
      >
        {typeof children === 'string' ? (
          <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: prepareHtmlString(children) }} />
        ) : (
          children
        )}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipContentWithPopupContext = withPopupContext(TooltipContent);

/**
 * A customized trigger component for the tooltip.
 */
const Trigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> & {
    /**
     * Used to prevent close of tooltip when clicking on trigger.
     * @caution It prevents events from propagating. Can cause unclickable items.
     * @remarks Use only for Icons.
     */
    preventOnClickEvent?: boolean;
  }
>(({ children, onClick, preventOnClickEvent = false, ...props }, ref) => {
  return (
    <TooltipPrimitive.Trigger
      onClick={
        onClick ??
        (preventOnClickEvent
          ? (e) => {
              e.preventDefault();
            }
          : undefined)
      }
      ref={ref}
      {...props}
    >
      {children}
    </TooltipPrimitive.Trigger>
  );
});
Trigger.displayName = TooltipPrimitive.Trigger.displayName;

export const Tooltip = Object.assign(TooltipPrimitive.Root, {
  Provider: TooltipPrimitive.Provider,
  Trigger,
  Content: TooltipContentWithPopupContext,
});

export default Tooltip;
