import { useEffect, useRef, useState } from 'react';

const DEFAULT_DEBOUNCE_TIMEOUT = 250;

export const useDebouncedCallback = <A extends any[]>(
  callback: (...args: A) => void,
  wait: number = DEFAULT_DEBOUNCE_TIMEOUT
) => {
  const argsRef = useRef<A>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [isPending, setIsPending] = useState(false);

  function cleanup() {
    if (timeout.current) clearTimeout(timeout.current);
  }

  function cancel() {
    cleanup();
  }

  useEffect(() => cleanup, []);

  function debouncedCallback(...args: A) {
    argsRef.current = args;

    cleanup();

    setIsPending(true);
    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        setIsPending(false);
        callback(...argsRef.current);
      }
    }, wait);
  }

  return [debouncedCallback, isPending, cancel] as const;
};
