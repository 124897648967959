import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { MultilineTextFieldProps, TextField, TextFieldProps } from '@components/field';
import { useFieldContext } from '@components/field/field-context';

export interface TextFieldWithFormikProps
  extends Omit<TextFieldProps, 'value' | 'onChange' | 'onBlur' | 'defaultValue'> {
  name: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string | null, defaultAction: () => void) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, value: string | null, defaultAction: () => void) => void;
}

export const TextFieldWithFormik = React.forwardRef<HTMLInputElement, TextFieldWithFormikProps>((props, ref) => {
  const [{ value }, { error, touched }, { setTouched, setValue }] = useField<string | null>(props.name);
  const { handleBlur, validateOnChange } = useFormikContext<any>();
  const { onChange, onBlur } = props;
  const { isLoading, disabled } = useFieldContext();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      void setTouched(true, false);
      void setValue(e.target.value === '' ? null : e.target.value, validateOnChange);
    },
    [setTouched, setValue, validateOnChange]
  );

  const handleChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChange ? onChange(e, e.target.value, () => handleChange(e)) : handleChange(e),
    [onChange, handleChange]
  );

  const handleBlurCallback = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) =>
      onBlur ? onBlur(e, e.target.value, () => handleBlur(e)) : handleBlur(e),
    [onBlur, handleBlur]
  );

  const finalProps: TextFieldProps = {
    ...props,
    value: props.value ?? value ?? '',
    name: props.name,
    onChange: handleChangeCallback,
    onBlur: handleBlurCallback,
    error: props.error ? props.error : touched ? error : undefined,
    isLoading: props.isLoading || isLoading,
    disabled: props.disabled || disabled,
  };

  return <TextField {...finalProps} ref={ref} />;
});
TextFieldWithFormik.displayName = 'TextFieldWithFormik';

export interface MultilineTextWithFormikProps
  extends Omit<MultilineTextFieldProps, 'value' | 'onChange' | 'onBlur' | 'defaultValue'> {
  name: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>, value: string | null, defaultAction: () => void) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>, value: string | null, defaultAction: () => void) => void;
}

export const MultilineTextWithFormik: React.FC<MultilineTextWithFormikProps> = (props) => {
  const [{ value }, { error, touched }, { setTouched, setValue }] = useField<string | null>(props.name);
  const { handleBlur, validateOnChange } = useFormikContext<any>();
  const { onChange, onBlur } = props;
  const { isLoading, disabled } = useFieldContext();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      void setTouched(true, false);
      void setValue(e.target.value === '' ? null : e.target.value, validateOnChange);
    },
    [setTouched, setValue, validateOnChange]
  );

  const handleChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange ? onChange(e, e.target.value, () => handleChange(e)) : handleChange(e),
    [onChange, handleChange]
  );

  const handleBlurCallback = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) =>
      onBlur ? onBlur(e, e.target.value, () => handleBlur(e)) : handleBlur(e),
    [onBlur, handleBlur]
  );

  const finalProps: MultilineTextFieldProps = {
    ...props,
    value: props.value ?? value ?? '',
    name: props.name,
    onChange: handleChangeCallback,
    onBlur: handleBlurCallback,
    error: props.error ? props.error : touched ? error : undefined,
    isLoading: props.isLoading || isLoading,
    disabled: props.disabled || disabled,
  };

  return <TextField.Multiline {...finalProps} />;
};
