export const personYoungerThen = (birth_date: string | null, age: number) => {
  if (!birth_date) {
    return false;
  }

  const birthDate = new Date(birth_date);
  const today = new Date();

  let ageDiff = today.getFullYear() - birthDate.getFullYear();

  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    ageDiff--;
  }

  return ageDiff < age;
};
