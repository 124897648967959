import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { FormikContext } from 'formik';
import get from 'lodash/get';
import { ChevronDown, ChevronUp, Info as InfoOutlined } from 'react-feather';
import { Tooltip } from '@components';
import { Collapsible } from '@components/collapsible';
import Divider from '@components/divider/divider';
import { IconButton } from '@components/icon-button';
import { IconStatus, StatusIcon } from '@components/status-icon';
import Typography from '@components/typography/typography';
import { commonCn } from '@utils/cn';

export interface SectionProps {
  name?: string;
  title: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  hideDivider?: boolean;
  action?: ReactNode;
  collapsable?: boolean;
  open?: boolean | null;
  onOpenChange?: React.Dispatch<React.SetStateAction<boolean>>;
  status?: string | boolean;
  infoText?: React.ReactNode;
  className?: string;
  titleIcon?: React.ReactNode;
}

const StatusIconComponent = ({
  name,
  status,
  icon,
}: {
  name?: string;
  status?: string | boolean;
  icon?: React.ReactNode;
}) => {
  const { errors } = useContext(FormikContext) || {};
  const showError = useMemo(() => {
    return name && get(errors, name);
  }, [errors, name]);

  const finalStatus = status || (showError && 'error');

  if (icon && !finalStatus) {
    return <div className="ps-flex">{icon}</div>;
  }

  if (finalStatus) {
    return (
      <div className="ps-flex items-center">
        {icon && <div className="ps-mr-2">{icon}</div>}
        <StatusIcon status={finalStatus as IconStatus} />
      </div>
    );
  }

  return null;
};

export const Section: FC<SectionProps> = ({
  name,
  title,
  children,
  defaultOpen = false,
  hideDivider = false,
  action = null,
  collapsable = false,
  open = null,
  onOpenChange,
  status,
  infoText,
  className,
  titleIcon,
}) => {
  const [localOpen, setLocalOpen] = useState<boolean>(defaultOpen);

  const isOpen = open !== null ? open : localOpen;
  const handleOpenChange = onOpenChange ?? setLocalOpen;

  const header = (
    <div
      className={`ps-flex ps-w-full ps-items-center ps-justify-between ${
        collapsable ? 'ps-cursor-pointer' : 'ps-cursor-default'
      }`}
    >
      <div className="ps-flex ps-w-full ps-items-center ps-space-x-2">
        <StatusIconComponent status={status} icon={titleIcon} name={name} />
        <div className="ps-flex ps-w-full ps-items-center">
          {typeof title === 'object' ? title : <Typography variant={'h3'}>{title}</Typography>}
          {infoText && (
            <Tooltip>
              <Tooltip.Trigger asChild preventOnClickEvent>
                <InfoOutlined className={'ps-ml-1 ps-text-gray-600 hover:ps-text-gray-700'} size={20} />
              </Tooltip.Trigger>
              <Tooltip.Content>{infoText}</Tooltip.Content>
            </Tooltip>
          )}
        </div>
      </div>
      {action && <div className="ps-ml-auto ps-flex">{action}</div>}
      {collapsable && (
        <IconButton>
          {isOpen ? <ChevronUp className="ps-text-black" /> : <ChevronDown className="ps-text-black" />}
        </IconButton>
      )}
    </div>
  );

  const divider = !hideDivider && (
    <div className={'ps-mt-1'}>
      <Divider />
    </div>
  );

  if (collapsable) {
    return (
      <div className={commonCn('ps-w-full', className)}>
        <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
          <Collapsible.Trigger asChild>{header}</Collapsible.Trigger>
          {divider}

          <Collapsible.Content>
            <div className={'ps-mt-3'}>{children}</div>
          </Collapsible.Content>
        </Collapsible>
      </div>
    );
  } else {
    return (
      <div className={commonCn('ps-w-full', className)}>
        {header}
        {divider}

        <div className={'ps-mt-3'}>{children}</div>
      </div>
    );
  }
};

export default Section;
