import React from 'react';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import { PatternFormatProps } from 'react-number-format';
import FieldWrapper from '@components/field/field-wrapper';
import FieldSkeleton from '@components/field/skeleton';
import { FieldPropsBase } from '@components/field/types';
import { Input, InputProps } from '@components/input';
import Textarea, { TextareaProps } from '@components/textarea';

type UncontrolledValues = { defaultValue: string; value?: never };
type ControlledValues = { value: string; defaultValue?: never };
type FieldValues = ControlledValues | UncontrolledValues;

export type TextFieldProps = FieldPropsBase &
  Omit<InputProps, 'value' | 'defaultValue' | 'error'> & { parentRef?: React.Ref<HTMLDivElement> } & FieldValues;

const TextFieldInner = (
  {
    isLoading,
    label,
    labelProps,
    required,
    error,
    helperText,
    infoText,
    value,
    defaultValue,
    parentRef,
    wrapperClassName,
    ...rest
  }: TextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  return (
    <FieldWrapper
      {...{ label, labelProps, required, infoText, helperText, error, inputId: rest.id, className: wrapperClassName }}
      ref={parentRef}
    >
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <Input
          value={value}
          defaultValue={defaultValue}
          {...errorAriaAttributes}
          {...helperTextAttributes}
          {...rest}
          id={id}
          ref={ref}
        />
      )}
    </FieldWrapper>
  );
};

export type MultilineTextFieldProps = FieldPropsBase &
  Omit<TextareaProps, 'value' | 'defaultValue' | 'type' | 'error'> & {
    inputRef?: React.Ref<HTMLTextAreaElement>;
    rows?: number;
  } & FieldValues;

const TextFieldMultilineInner = (
  {
    isLoading,
    label,
    labelProps,
    required,
    error,
    helperText,
    infoText,
    value,
    defaultValue,
    inputRef,
    inputClassName,
    ...rest
  }: MultilineTextFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  rest.rows ??= 3;

  if (isLoading) {
    return (
      <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea rows={rest.rows} />
    );
  }

  return (
    <FieldWrapper {...{ label, labelProps, required, infoText, helperText, error, inputId: rest.id }} ref={ref}>
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <Textarea
          value={value}
          defaultValue={defaultValue}
          inputClassName={inputClassName}
          {...errorAriaAttributes}
          {...helperTextAttributes}
          {...rest}
          id={id}
          ref={inputRef as React.ForwardedRef<HTMLTextAreaElement>}
        />
      )}
    </FieldWrapper>
  );
};

export type FormatTextFieldProps = FieldPropsBase &
  Omit<PatternFormatProps, 'format' | 'mask'> &
  Omit<InputProps, 'value' | 'defaultValue' | 'type' | 'error'> & {
    inputRef?: React.Ref<HTMLInputElement>;
    mask: ReactInputMaskProps['mask'];
    maskChar?: ReactInputMaskProps['maskChar'];
  } & FieldValues;

const FormatTextFieldInner = (
  {
    isLoading,
    label,
    labelProps,
    required,
    error,
    helperText,
    infoText,
    value,
    defaultValue,
    mask,
    maskChar,
    inputRef,
    ...rest
  }: FormatTextFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  return (
    <FieldWrapper {...{ label, labelProps, required, infoText, helperText, error, inputId: rest.id }} ref={ref}>
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <ReactInputMask
          mask={mask}
          maskChar={maskChar ?? ' '}
          alwaysShowMask={true}
          defaultValue={defaultValue}
          value={value}
          onChange={rest.onChange}
          onBlur={rest.onBlur}
          disabled={rest.disabled}
        >
          {/* @ts-expect-error poor ReactInputMask typings */}
          {(maskInputProps) => {
            return (
              <Input
                {...errorAriaAttributes}
                {...helperTextAttributes}
                {...rest}
                id={id}
                ref={inputRef as React.ForwardedRef<HTMLInputElement>}
                {...maskInputProps}
              />
            );
          }}
        </ReactInputMask>
      )}
    </FieldWrapper>
  );
};

const MemoizedTextFieldInner = React.memo(React.forwardRef(TextFieldInner));
MemoizedTextFieldInner.displayName = 'TextField';

const MemoizedMultilineTextFieldInner = React.memo(React.forwardRef(TextFieldMultilineInner));
MemoizedMultilineTextFieldInner.displayName = 'TextFieldMultiline';

const MemoizedFormatTextFieldInner = React.memo(React.forwardRef(FormatTextFieldInner));
MemoizedFormatTextFieldInner.displayName = 'FormatTextField';

export const TextField = MemoizedTextFieldInner as typeof MemoizedTextFieldInner & {
  Multiline: typeof MemoizedMultilineTextFieldInner;
  Format: typeof MemoizedFormatTextFieldInner;
  Skeleton: typeof FieldSkeleton;
};
Object.assign(TextField, {
  Multiline: MemoizedMultilineTextFieldInner,
  Format: MemoizedFormatTextFieldInner,
  Skeleton: FieldSkeleton,
});
