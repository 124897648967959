import React, { FC, ReactNode } from 'react';
import { Tooltip } from '.';

type ContentProps = React.ComponentPropsWithoutRef<typeof Tooltip.Content>;
type ProsightTooltipProps = ContentProps & {
  children: React.ReactElement<any, any>;
  infoText?: ReactNode;
  preventTriggerOnClickEvent?: boolean;
};

export const ProsightTooltip: FC<ProsightTooltipProps> = ({
  infoText,
  children,
  preventTriggerOnClickEvent,
  ...rest
}) => {
  if (!infoText) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger preventOnClickEvent={preventTriggerOnClickEvent} asChild>
        {children}
      </Tooltip.Trigger>
      <Tooltip.Content {...rest}>{infoText}</Tooltip.Content>
    </Tooltip>
  );
};
